import moment from "moment";

export function stringToDatetime(dateString) {
  return `${dateString.split("/").reverse().join("-")} 00:00:00.0000000`;
}

export function stringToDatetimeZone(dateString) {
  return `${dateString.split("/").reverse().join("-")} 00:00:00.000z`;
}

export function filterData(valor) {
  return valor ? valor.split("T")[0].split("-").reverse().join("/") : "";
}

export function filterDataHora(valor) {
  var date = new Date(valor);
  return valor ? moment(date).format("DD/MM/YYYY [às] HH[h]mm") : "";
}

export function nowDateString() {
  var date = new Date();
  return moment(date).format("YYYY-MM-DD_HH:mm:ss");
}

export function formatBirthdate(birthdate) {
  // Create a new Date object using the birthdate string
  var date = new Date(birthdate);
  
  // Extract day, month, and year from the Date object
  var day = date.getDate();
  var month = date.getMonth() + 1; // January is 0
  var year = date.getFullYear();
  
  // Format the birthdate as 'Month Day, Year'
  var formattedDate = day + '/' + month + '/' + year;
  
  return formattedDate;
}